import { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import AnimatedIcon from '../animated_icon'
import * as heartAnimation from 'public/animations/heart.json'
import s from './HeartIcon.module.scss'
import { clx } from '@utils'

function HeartIcon({ onClick, isActive, className, ...props }) {

   const [isAnimated, setIsAnimated] = useState(isActive)

   const token = useSelector(state => state.token)

   useEffect(() => {
      setIsAnimated(isActive)
   }, [isActive])

   const [ReactPixel, setReactPixel] = useState(null)
   //facebook PIXEL
   useEffect(() => {
      import('react-facebook-pixel')
         .then((module) => {
            setReactPixel(module.default)
         })
   }, [])


   const handleIconClick = () => {
      onClick()
      ReactPixel.track( 'AddToWishlist') //facebook PIXEL

      if (token) {
         setIsAnimated(!isAnimated)
      }
   }

   return (
      <AnimatedIcon
         isAnimated={ isAnimated }
         isFinished={ isActive }
         onClick={ handleIconClick }
         className={ clx(s.icon, className) }
         animationData={ heartAnimation }
         { ...props }
      />
   )
}

HeartIcon.defaultProps = {
   onClick: () => {}
}

export default memo(HeartIcon)
